// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/Icon.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/Icon.tsx");
  import.meta.hot.lastModified = "1709730911216.526";
}
// REMIX HMR END

import React, { createElement } from "react";
import { cn } from "~/utils/cn";
import { NamedIcon } from "./NamedIcon";
/** Use this icon to either render a passed in React component, or a NamedIcon/CompanyIcon */
export function Icon(props) {
  if (!props.icon) return null;
  if (typeof props.icon === "string") {
    return <NamedIcon name={props.icon} className={props.className ?? ""} fallback={<></>} />;
  }
  if (typeof props.icon === "function") {
    const Icon = props.icon;
    return <Icon className={props.className} />;
  }
  if (React.isValidElement(props.icon)) {
    return <>{props.icon}</>;
  }
  if (props.icon && typeof props.icon === "object" && ("type" in props.icon || "$$typeof" in props.icon)) {
    return createElement(props.icon, {
      className: props.className
    });
  }
  console.error("Invalid icon", props);
  return null;
}
_c = Icon;
export function IconInBox({
  boxClassName,
  ...props
}) {
  return <div className={cn("grid h-9 w-9 place-content-center rounded-sm border border-charcoal-750 bg-charcoal-850", boxClassName)}>
      <Icon icon={props.icon} className={cn("h-6 w-6", props.className)} />
    </div>;
}
_c2 = IconInBox;
var _c, _c2;
$RefreshReg$(_c, "Icon");
$RefreshReg$(_c2, "IconInBox");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;